.nav {
    background-color: rgba(255,255,255,.9);
    padding: 8px 0;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.8)!important;
}

/* .navBar {
    background-color: #fff;
} */

/*Floats each item to the left with padding of 14 & 16 px.
  Removes the underline with text decoration = none.*/
.navBar a {
    float: left;
    color: grey;
    padding: 0 16px;
    text-decoration: none;
    line-height: 36px;
}

/*Background color change during hover state*/
.navBar a:hover {
    color: #b32333;
}

/*Hides the menu Icon which will show when the nav needs to be responsive*/
.navBar .icon {
    display: none;
}

@media screen and (min-width: 480px) {
    .mobile {
        display: none;
    } 
}


/*Set your custom screen width here replacing 700*/
@media screen and (max-width: 700px) {
    .hide {
        display: none;
      }

    /*Ignores the first link (which is Home) in the div and       applies 'display = none' to everything else.   Basically hiding everything but Home*/
    .navBar a:not(:first-child) {
        display: none;
    }

    /*Brings the menu icon into view and floats it to the right*/
    .navBar a.icon {
        display: block;
        float: right;
    }

    /*The navBar class will be changed to 'navBar responsive' using JS. This chunk of CSS makes the menu icon stay where it is by making the position absolute within it's parent 'right top corner'. Without this, the icon will get kicked around when the items are collapsed and expanded*/
    .navBar.responsive {
        position: relative;
    }

    .navBar.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    /*Removes the originally set float and brings them to view*/
    .navBar.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}