body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("https://storage.googleapis.com/static_mmxfront/bg.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

form,
.card {
  background-color: #fff;
  padding: 1.6rem;
  border-radius: 6px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .8) !important;
}

small a {
  color: #b32333;

  &:hover {
    color: #b32333;
  }
}

label {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.2rem;
  margin-bottom: unset;
}

/* .button.button-primary,
button.button-primary,
input[type=button].button-primary,
input[type=reset].button-primary, */
input[type=submit].button-primary {
  background-color: #b32333;
  border-color: #b32333;
}

/* .button.button-primary:focus,
.button.button-primary:hover,
button.button-primary:focus,
button.button-primary:hover,
input[type=button].button-primary:focus,
input[type=button].button-primary:hover,
input[type=reset].button-primary:focus,
input[type=reset].button-primary:hover, */

input[type=submit].button-primary:focus,
input[type=submit].button-primary:hover {
  background-color: #b32333;
  border-color: #b32333;
}

input[type=email]:focus,
input[type=tel]:focus,
input[type=text]:focus,
select:focus {
  border: 1px solid #b32333;
}

input[type=submit]:disabled,
button[disabled=disabled],
button:disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.title {
  text-transform: uppercase;
  font-weight: 500;
}

::placeholder {
  font-weight: 500;
}

.wa {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

.phone {
  position: fixed;
  bottom: 25px;
  left: 20px;
  display: block;
  width: 62px;
  height: 62px;
  text-align: center;
  border-radius: 60px;
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .8) !important;
}

.select {
  margin-bottom: 1.5rem;
}

/* .create-select {
  color: #000;

  [class$="valuecontainer"] {
    min-height: 38px !important;
    max-height: 38px !important;
  }

  [class$="indicatorscontainer"] {
    min-height: 38px !important;
    max-height: 38px !important;
  }

  [class$="-input"] {
    min-height: 38px !important;
    max-height: 38px;
    padding: 0;
  }

  [class$="-control"] {
    min-height: 38px !important;
    max-height: 38px !important;

  }
} */

/* @media (max-width: 700px) {
  .hide {
    display: none;
  }
} */


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

.btn-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  gap: 8px;
  width: 97%;
}

.btn-whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25d366 !important;
  color: white;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #25d366;
  cursor: pointer;
  box-sizing: border-box;
  height: 38px;
  svg {
    display: inline-flex;
    margin-right: 5px;
  }

  
}

.btn-whatsapp.disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.icon {
  display: inline-block;
  vertical-align: -0.125em;
  fill: currentcolor;
}


.footer {
  margin-top: 1rem;
  padding: 1.5rem 0;
  background-color: #111;

  .row {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;

    img {
      vertical-align: middle;
    }
  }

  .social-icons a {
    font-size: 2rem;
    display: inline-block;
    padding: 0 .75rem;
  }

  a {
    font-size: 75%;
    display: inline-block;
    text-decoration: none;
    color: gray;

    &:hover {
      opacity: .8;
    }
  }
}